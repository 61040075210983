<template>
  <div style="padding: 50px;">
    <v-alert
      outlined
      type="warning"
      prominent
      border="left"
    >
      Debe seleccionar un entorno y un �rea de trabajo. Para hacerlo seleccione una opci�n del men� superior.  
    </v-alert>
  </div>
</template>

<script>
export default {

}
</script>

